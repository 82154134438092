import React from "react";
import BgHero from "../BgHero";
import bgHero from "../../images/menu/tea.jpg";
import Menu from "../../components/menu/Menu";
import aperitifs from "./menu/aperitif/aperitifs.json";
import soft from "./menu/aperitif/soft.json";
import spiritueux from "./menu/aperitif/spiritueux.json";
import whiskies from "./menu/aperitif/whiskies.json";
import bieres from "./menu/aperitif/bieres.json";
import classiques from "./menu/cocktails/classiques.json";
import creations from "./menu/cocktails/creations.json";
import mocktails from "./menu/cocktails/mocktails.json";
import shoots from "./menu/cocktails/shoots.json";
import rogue from "./menu/vins/rogue.json";
import blanc from "./menu/vins/blanc.json";
import rose from "./menu/vins/rose.json";
import gris from "./menu/vins/gris.json";

export default function Main() {
  const tabMenuAperitifs = [
    "Les Apéritifs",
    "Soft Drinks",
    "Les Spiritueux",
    "Les Whiskies",
    "Les Biéres",
  ];

  const tabMenuCocktails = [
    "Les Classiques A 80DH",
    "Les Creations",
    "Les Mocktails A 60DH",
    "Les Shoots A 35DH",
  ];

  const tabMenuVins = ["Rogue", "Blanc", "Rosé", "Gris"];

  const menuItemsAperitifs = [aperitifs, soft, spiritueux, whiskies, bieres];
  const menuItemsCocktails = [classiques, creations, mocktails, shoots];
  const menuItemsVins = [rogue, blanc, rose, gris];

  const headingStyles = {
    textAlign: "center",
    marginBottom: "50px",
    fontSize: "2.5rem",
  };

  return (
    <main>
      <BgHero heading="Cocktails" bg={bgHero} />
      <div className="container" style={{ margin: "75px auto" }}>
        <h2 style={headingStyles}>L'apéritif</h2>
        <Menu tabMenu={tabMenuAperitifs} menuItems={menuItemsAperitifs} />
        <h2 style={headingStyles}>Cocktails & Shoots</h2>
        <Menu tabMenu={tabMenuCocktails} menuItems={menuItemsCocktails} />
        <h2 style={headingStyles}>Vins</h2>
        <Menu tabMenu={tabMenuVins} menuItems={menuItemsVins} />
      </div>
    </main>
  );
}
