import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Main from "../components/Cocktails/Main";
import { Helmet } from "react-helmet";
export default function Cocktails() {
  return (
    <>
      <Helmet title="Cocktails Menu - La Noce" defer={false} />

      <Nav />
      <Main />
      <Footer />
    </>
  );
}
